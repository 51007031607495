@let displayFn = configSig().displayFn;
@let label = configSig().label;
@let formControl = configSig().formControl;
@let readonly = configSig().readonly;
@let data = configSig().data();
@let appearance = configSig().appearance;

<mat-form-field [appearance]="appearance">
  @if (label) {
    <mat-label>{{ label | ocpTranslate }}</mat-label>
  }
  <input
    type="text"
    matInput
    [formControl]="formControl"
    (input)="onInputChange($event)"
    [matAutocomplete]="auto"
    [readonly]="readonly"
  />
  <mat-autocomplete
    requireSelection
    [displayWith]="displayFn"
    [class]="panelClass"
    #auto="matAutocomplete"
  >
    @for (item of data; track item) {
      <mat-option [value]="item">
        {{ displayFn(item) }}
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
