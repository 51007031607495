import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  effect,
  ElementRef,
  HostBinding,
  input,
  isSignal,
  type OnDestroy,
  type OnInit,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteModule, MatOption } from '@angular/material/autocomplete';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

import { OcpTranslatePipe } from '@ocp/fusion-cdk/translate';
import { OcpUtilityDomService } from '@ocp/utils/services';

import type { OcpControlInitialValue } from '../types';
import type { OcpAutocompleteFlatConfig } from './autocomplete-config.model';

const OCP_FORM_FIELD_READONLY_CLASS = 'ocp-form-field-readonly';
const OCP_FORM_FIELD_HIDE_ERROR_CLASS = 'hide-error';

// TODO: #UI_FEAT Add element selection by ENTER if the value of the input is equal to one of the values
// It should select the first value
// TODO: #TBD Should the value to be selected even if it's partially met the value
//  (i.e. The project name "Test project", user enter "Test" and press ENTER)
@Component({
  standalone: true,
  selector: 'ocp-autocomplete-flat',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './autocomplete-flat.component.html',
  styleUrl: './autocomplete-flat.component.scss',
  providers: [OcpUtilityDomService],
  imports: [
    MatFormField,
    MatLabel,
    OcpTranslatePipe,
    MatAutocompleteModule,
    MatOption,
    ReactiveFormsModule,
    MatInput,
  ],
})
export class OcpAutocompleteFlatComponent<TEntity> implements OnInit, OnDestroy {
  @HostBinding('class.ocp-autocomplete-flat') hostCssClass = true;
  public readonly panelClass = 'ocp-autocomplete-flat-panel';

  configSig = input.required<OcpAutocompleteFlatConfig<TEntity>>({ alias: 'config' });

  public autocomplete = viewChild<MatAutocomplete>(MatAutocomplete);

  constructor(
    private _elementRef: ElementRef<HTMLElement>,
    private _utilityDomService: OcpUtilityDomService,
    private _destroyRef: DestroyRef,
  ) {
    effect(() => {
      this._utilityDomService.updateClass(
        this._elementRef,
        OCP_FORM_FIELD_READONLY_CLASS,
        this.configSig().readonly,
      );

      this._utilityDomService.updateClass(
        this._elementRef,
        OCP_FORM_FIELD_HIDE_ERROR_CLASS,
        this.configSig().appearanceParams.hideError ?? false,
      );
    });

    effect(() => {
      const { data, formControl, selectedValue } = this.configSig();

      if (!data().length && formControl.value != null) {
        return;
      }

      if (!selectedValue) {
        return;
      }

      // Explanation: If the value is passed as signal, it should be sync with the value selected in the control
      // If selected value is empty, but control is not, it will trigger cleaning of the control
      if (isSignal(selectedValue)) {
        const resolvedValue = selectedValue();

        if (resolvedValue.value == null && formControl.value != null) {
          formControl.setValue(null);
          setTimeout(() => this._toggleSelectedOption(null, false), 0);
          return;
        }

        this._setInitialValue(resolvedValue);
      } else {
        this._setInitialValue(selectedValue);
      }
    });
  }

  ngOnInit(): void {
    this.configSig().onInit?.();

    this.configSig()
      .formControl.valueChanges.pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((value: TEntity | string | null) => {
        if (typeof value === 'string') {
          // input value if requireSelection is not set
          return;
        }
        // Handle select change
        this.configSig().onSelect?.(value);
      });
  }

  ngOnDestroy(): void {
    this.configSig().onDestroy?.();
  }

  // Handle input changes
  onInputChange(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;
    this.configSig().onInput?.(inputValue);
  }

  private _toggleSelectedOption(value: TEntity | null, isToSelect: boolean): void {
    const autocomplete = this.autocomplete();

    if (!autocomplete) {
      return;
    }

    autocomplete.options.forEach((option) => {
      if (value != null && this.configSig().compareFn(option.value, value)) {
        isToSelect ? option.select() : option.deselect();
        return;
      }

      if (option.selected) {
        option.deselect();
      }
    });
  }

  private _setInitialValue(initialValue: OcpControlInitialValue<TEntity>): void {
    const { data, formControl, compareFn } = this.configSig();

    let valueToSet: TEntity | undefined;

    if (initialValue.type === 'property') {
      valueToSet = data().find((el) => el[initialValue.prop] === initialValue.value);
    } else {
      valueToSet = data().find((el) => compareFn(el, initialValue.value ?? null));
    }

    if (valueToSet != null && !compareFn(formControl.value, valueToSet)) {
      formControl.setValue(valueToSet);
      // TODO: #UI_FEAT update behavior so we start calling method after autocomplete options exists
      setTimeout(() => this._toggleSelectedOption(valueToSet, true), 0);
    }
  }
}
