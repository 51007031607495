import { type Signal } from '@angular/core';

import type { OcpRequiredAndPartial } from '@ocp/utils/types';

import type { OcpControlInitialValue } from '../types';
import { OcpControl, type OcpControlProps } from '../models';

export type OcpAutocompleteFlatConfigProps<TEntity> = OcpControlProps<TEntity> &
  OcpRequiredAndPartial<
    OcpAutocompleteFlatConfig<TEntity>,
    'data',
    | 'compareFn'
    | 'displayFn'
    | 'formControl'
    | 'onDestroy'
    | 'onInit'
    | 'onInput'
    | 'onSelect'
    | 'selectedValue'
  >;

export class OcpAutocompleteFlatConfig<TEntity> extends OcpControl<TEntity> {
  type = 'autocomplete' as const;
  data!: Signal<TEntity[]>;

  displayFn: (value: TEntity | null) => string = (value: TEntity | null) =>
    value != null ? `${value}` : '';
  compareFn: (value1: TEntity | null, value2: TEntity | null) => boolean = (value1, value2) =>
    value1 === value2;
  // TODO: #MEDIUM split selected and initial values
  selectedValue: OcpControlInitialValue<TEntity> | Signal<OcpControlInitialValue<TEntity>> | null =
    null;
  onInput?: (value: string) => void;
  onSelect?: (value: TEntity | null) => void;
  onInit?: () => void;
  onDestroy?: () => void;

  constructor(params: OcpAutocompleteFlatConfigProps<TEntity>) {
    super(params);
    Object.assign(this, params);
  }
}
