@let appearance = configSig().appearance;
@let label = configSig().label;
@let formControl = configSig().formControl;
@let readonly = configSig().readonly;
@let tooltipConfig = configSig().tooltipConfig;

<mat-form-field [appearance]="appearance" class="field">
  @if (label) {
    <mat-label>{{ label | ocpTranslate }}</mat-label>
  }
  <input
    matInput
    [matTooltip]="tooltipConfig?.text"
    [matTooltipShowDelay]="tooltipConfig?.showDelay"
    [formControl]="formControl"
    (input)="onInputChange($event)"
    (click)="readonly && $event.preventDefault()"
    type="text"
  />
</mat-form-field>
