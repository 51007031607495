<form [formGroup]="configSig().getFormGroup()" class="form">
  @for (control of configSig().controlsSig(); track control) {
    @switch (control.type) {
      @case ('input') {
        <ocp-input [config]="control" />
      }
      @case ('select') {
        <ocp-select [config]="control" />
      }
      @case ('autocomplete') {
        <ocp-autocomplete-flat [config]="control" />
      }
    }
  }

  @let formActions = configSig().actionsSig();

  @if (formActions?.length) {
    <div class="form-actions">
      @for (action of formActions; track $index) {
        <ocp-button-universal [config]="action"></ocp-button-universal>
      }
    </div>
  }
</form>
