import { computed, signal, type Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { type FormControlStatus, FormGroup } from '@angular/forms';

import type { OcpButtonRealisation } from '@ocp/ui-kit/button';
import type { OcpFormControlify } from '@ocp/utils/types';

import { OcpAutocompleteFlatConfig } from '../autocomplete';
import { OcpInput } from '../input';
import { OcpSelectConfig } from '../select';

type OcpControlConfig<K> = OcpAutocompleteFlatConfig<K> | OcpInput | OcpSelectConfig<K>;
type OcpControlMap<TForm> = {
  [K in keyof TForm]: OcpAutocompleteFlatConfig<TForm[K]> | OcpInput | OcpSelectConfig<TForm[K]>;
};

export class OcpForm<TForm> {
  private _formGroup: FormGroup<OcpFormControlify<TForm>> = new FormGroup({}) as any;

  private _controlsDataSig = signal<OcpControlMap<TForm>>({} as any);
  private _actionsDataSig = signal<OcpButtonRealisation[]>([]);

  public readonly formGroupValueChangesSig = toSignal(this._formGroup.valueChanges, {
    initialValue: this._formGroup.value,
  }) as Signal<TForm>;
  public readonly formGroupStatusChangesSig = toSignal(this._formGroup.statusChanges, {
    initialValue: this._formGroup.status,
  }) as Signal<FormControlStatus>;

  public readonly controlsSig = computed(() => {
    const values = Object.values(this._controlsDataSig());
    return values.length ? (values as OcpControlConfig<TForm[keyof TForm]>[]) : [];
  });
  public readonly actionsSig = computed(() => this._actionsDataSig());

  public getFormGroup(): FormGroup<OcpFormControlify<TForm>> {
    return this._formGroup;
  }

  public setControls(controls: OcpControlMap<TForm>): this {
    this._initializeFormGroup(controls);
    queueMicrotask(() => {
      this._controlsDataSig.set(controls);
    });
    return this;
  }

  public setActions(actions: OcpButtonRealisation[]): this {
    queueMicrotask(() => {
      this._actionsDataSig.set(actions);
    });
    return this;
  }

  private _initializeFormGroup(controls: {
    [K in keyof TForm]: OcpAutocompleteFlatConfig<TForm[K]> | OcpInput | OcpSelectConfig<TForm[K]>;
  }): void {
    this._removeFormGroupControls();

    const controlKeys = Object.keys(controls) as (keyof TForm)[];
    // TODO: #LOW Remove any here
    controlKeys.forEach((key) => {
      this._formGroup.addControl(key as any, controls[key].formControl as any);
    });
  }

  private _removeFormGroupControls(): void {
    Object.keys(this._formGroup.controls).forEach((controlName) => {
      this._formGroup.removeControl(controlName as any);
    });
  }
}
