import { ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { OcpButtonUniversalComponent } from '@ocp/ui-kit/button';

import { OcpAutocompleteFlatComponent } from '../autocomplete';
import { OcpInputComponent } from '../input';
import { OcpSelectComponent } from '../select';
import type { OcpForm } from './form.model';

@Component({
  selector: 'ocp-form',
  standalone: true,
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    OcpInputComponent,
    OcpSelectComponent,
    OcpButtonUniversalComponent,
    OcpAutocompleteFlatComponent,
  ],
})
export class OcpFormComponent<T> {
  @HostBinding('class.ocp-form') hostCssClass = true;

  configSig = input.required<OcpForm<T>>({
    alias: 'config',
  });
}
