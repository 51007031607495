import {
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  HostBinding,
  input,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatTooltip } from '@angular/material/tooltip';

import { OcpTranslatePipe } from '@ocp/fusion-cdk/translate';
import { OcpUtilityDomService } from '@ocp/utils/services';

import { OcpInput } from './input.model';

const OCP_FORM_FIELD_READONLY_CLASS = 'ocp-form-field-readonly';

@Component({
  selector: 'ocp-input',
  standalone: true,
  templateUrl: './input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatFormField, MatInput, MatLabel, ReactiveFormsModule, OcpTranslatePipe, MatTooltip],
  providers: [OcpUtilityDomService],
})
export class OcpInputComponent {
  @HostBinding('class.ocp-input') hostCssClass = true;

  configSig = input.required<OcpInput>({ alias: 'config' });

  constructor(
    private _elementRef: ElementRef<HTMLElement>,
    private _utilityDomService: OcpUtilityDomService,
  ) {
    effect(() => {
      const isReadOnly = this.configSig().readonly;
      const control = this.configSig().formControl;
      const isControlDisabled = this.configSig().disabled();

      this._utilityDomService.updateClass(
        this._elementRef,
        OCP_FORM_FIELD_READONLY_CLASS,
        isReadOnly,
      );

      isReadOnly || isControlDisabled ? control.disable() : control.enable();
    });
  }

  // Handle input changes
  onInputChange(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.configSig().onInput?.(value);
  }
}
