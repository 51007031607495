import type { OcpRequiredAndPartial } from '@ocp/utils/types';
import { OcpTooltipConfig } from '@ocp/ui-kit/tooltip';

import { OcpControl, type OcpControlProps } from '../models';

type OcpInputProps = OcpControlProps<string> &
  OcpRequiredAndPartial<OcpInput, never, 'onInput' | 'tooltipConfig'>;

export class OcpInput extends OcpControl<string> {
  type = 'input' as const;

  tooltipConfig?: OcpTooltipConfig;

  onInput?: (value: string) => void;

  constructor(props: OcpInputProps) {
    super(props);
    Object.assign(this, props);
  }
}
